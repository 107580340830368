html,
body {
  height: 100vh;
  width: 100vw;
  padding: 0px;
  margin: 0px;
  font-family: "Helvetica", "Paralucent";
  overscroll-behavior: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  background: url("./assets/background.png") no-repeat center center !important;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.75);
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

@media screen and (min-width: 1900px) {
  .body:before {
    -webkit-background-size: 100vw;
    -moz-background-size: 100vw;
    -o-background-size: 100vw;
    background-size: 100vw;
  }
}

@media (max-width: 600px) {
  html, body {
    overflow-y: scroll;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0px;
  padding: 0px;
}

p {
  margin: 0px;
  padding: 0px;
}
